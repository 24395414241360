<template>
  <div class="login">
    <h1>Login</h1>
    <form @submit.prevent="login">
      <div class="mb-3">
        <label for="email" class="form-label">Email :</label>
        <input type="text" v-model="email" class="form-control" id="email"/>
      </div>
      <div class="mb-3">
        <label for="password" class="form-label">Password :</label>
        <input type="password" v-model="password" class="form-control" id="password"/>
      </div>
      <button type="submit" class="btn btn-primary">Login</button>

      <!-- Message d'erreur si les credentials sont incorrects -->
      <div v-if="errorMessage" class="alert alert-danger mt-3">
        {{ errorMessage }}
      </div>
    </form>
  </div>
</template>

<script>
export default {
  name: 'UserLogin',
  data() {
    return {
      email: '',
      password: '',
      errorMessage: '', // Pour stocker le message d'erreur
    };
  },
  methods: {
    async login() {
      try {
        // Appel pour obtenir le token CSRF
        await fetch('https://api.testr4web.fr/sanctum/csrf-cookie', {
          credentials: 'include', 
        });

        // Envoi de la requête de connexion
        const response = await fetch('https://api.testr4web.fr/api/login', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            email: this.email,
            password: this.password,
          }),
          credentials: 'include',
        });

        const data = await response.json();
        if (response.ok) {
          // Si la connexion réussit
          localStorage.setItem('token', data.token);
          this.$router.push('/scan');
        } else {
          // Si la connexion échoue
          this.errorMessage = data.message || 'Login failed. Please try again.';
        }
      } catch (error) {
        console.error('Error:', error);
        this.errorMessage = 'An error occurred during login. Please try again later.';
      }
    },
  },
};
</script>

<style scoped>
/* Ajoutez vos styles ici */
</style>
