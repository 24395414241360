<template>
  <div id="app" class="container">
    <header>
      <h1>Time Attitude : ticket scanner</h1>

      <nav class="navbar navbar-expand-lg navbar-light bg-light">
        <div class="container-fluid">
          <a class="navbar-brand" href="#">
            <img src="/img/icons/android-chrome-192x192.png" alt="" width="60" height="50">
          </a>
          <router-link class="navbar-brand" to="/">Login</router-link>
          <router-link class="navbar-brand" to="/scan">Scan QR Code</router-link>
          <router-link class="navbar-brand" to="/register">Register</router-link>
        </div>
      </nav>
    </header>
    <main>
      <router-view></router-view>
    </main>
    <footer>
      <!-- Add footer content if needed -->
    </footer>
  </div>
</template>

<script>

export default {
  name: 'App',
};
</script>

<style>
#app {
  font-family: Arial, Helvetica, sans-serif;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  justify-content: space-between;
}

header {
  padding: 10px;
  text-align: center;
}
header h1{
  margin-top: 1.5rem;
    margin-bottom: 1.5rem;
    font-weight: 400;
}

main {
  flex-grow: 1; /* Makes sure main content area expands to fill remaining space */
  padding: 20px;
}

footer {
  background-color: #f8f9fa;
  padding: 10px;
  text-align: center;
  border-top: 1px solid #ccc;
}
</style>
