import { createRouter, createWebHistory } from 'vue-router';
import UserLogin from '../components/UserLogin.vue';
import UserRegister from '../components/UserRegister.vue';
import QrCodeScanner from '../components/QrCodeScanner.vue';

// Définition des routes de l'application
const routes = [
  {
    path: '/',
    name: 'UserLogin',
    component: UserLogin,
  },
  {
    path: '/register',
    name: 'UserRegister',
    component: UserRegister,
  },
  {
    path: '/scan',
    name: 'QrCodeScanner',
    component: QrCodeScanner,
    meta: { requiresAuth: true }, // Indique que cette route nécessite une authentification
  },
];

// Création du routeur avec l'historique basé sur l'URL
const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

// Middleware de navigation avant chaque changement de route
router.beforeEach((to, from, next) => {
  console.log(`Navigating to: ${to.fullPath}`); // Ajout de logs pour voir la navigation
  console.log(`Requires Auth: ${to.meta.requiresAuth}`);
  console.log(`Is Authenticated: ${isAuthenticated()}`);

  if (to.meta.requiresAuth && !isAuthenticated()) {
    console.log('User not authenticated. Redirecting to login.');
    next({ name: 'UserLogin' }); // Redirection vers la page de login par nom de route
  } else {
    console.log('User authenticated or route does not require auth. Proceeding.');
    next(); // Sinon, permet la navigation vers la route demandée
  }
});

// Fonction pour vérifier si l'utilisateur est authentifié
function isAuthenticated() {
  var tok = localStorage.getItem('token');
  console.log(tok);
  // Exemple de vérification : si un jeton est présent dans le stockage local, l'utilisateur est considéré comme authentifié
  return localStorage.getItem('token') !== "undefined";
}

export default router;
