<template>
  <div>
    <QrcodeStream @decode="onDecode" @init="onInit" @error="onError"></QrcodeStream>
    <p v-if="decodedQrCode">QR Code scanné : {{ decodedQrCode }}</p>
    <p v-if="apiResponse">Réponse API : {{ apiResponse }}</p>
    <p v-if="error">{{ error }}</p>
  </div>
</template>

<script>
import { QrcodeStream } from 'vue3-qrcode-reader';

export default {
  components: {
    QrcodeStream
  },
  data() {
    return {
      decodedQrCode: null,
      apiResponse: null,
      error: null
    };
  },
  methods: {
    async onDecode(decodedText) {
      this.decodedQrCode = decodedText;

      // Récupération du token depuis le localStorage
      const token = localStorage.getItem('token');
      
      // Encodage des paramètres dans l'URL
      const url = new URL('https://api.testr4web.fr/api/user');
      url.searchParams.append('qrCode', decodedText);

      try {
        const response = await fetch(url.toString(), {
          method: 'GET',
          headers: {
            'Authorization': `Bearer ${token}`  // Ajout du token dans les en-têtes
          },
        });
        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }
        const data = await response.json();
        this.apiResponse = data;
      } catch (error) {
        this.error = `Erreur lors de l'appel API: ${error.message}`;
      }
    },
    onInit(promise) {
      promise.catch(error => {
        this.error = error.message;
      });
    },
    onError(error) {
      this.error = error.message;
    }
  }
};
</script>

<style>
/* Ajoutez vos styles ici si nécessaire */
</style>
