<template>
  <div class="login">
    <h1>Register</h1>
    <form @submit.prevent="register">

      <div class="mb-3">
        <label for="name" class="form-label">Name:</label>
        <input type="text" v-model="name"  class="form-control"/>
      </div>
      <div class="mb-3">
        <label for="email" class="form-label">Email:</label>
        <input type="text" v-model="email" class="form-control"/>
      </div>
      <div class="mb-3">
        <label for="password" class="form-label">Password:</label>
        <input type="password" v-model="password" class="form-control"/>
      </div>
      <div class="mb-3">
        <label for="password_confirmation" class="form-label">Password confirmation:</label>
        <input type="password" v-model="password_confirmation" class="form-control"/>
      </div>
      <button type="submit">Register</button>
    </form>
  </div>
</template>

<script>
export default {
  name: 'UserRegister',
  data() {
    return {
      email: '',
      name: '',
      password: '',
      password_confirmation: '',
    };
  },
  methods: {
    async register() {
      try {
        // Appel à l'endpoint sanctum/csrf-cookie pour obtenir le token CSRF
        // await fetch('http://api.ricl4666.odns.fr/sanctum/csrf-cookie', {
        //   credentials: 'include'
        // });

        const response = await fetch('https://api.testr4web.fr/api/register', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            email: this.email,
            name: this.name,
            password: this.password,
            password_confirmation: this.password_confirmation,
          }),
          //credentials: 'include'
        });
        
        const data = await response.json();
        if (response.ok) {
          localStorage.setItem('token', data.token);
          this.$router.push('/login');
        } else {
          alert('Register failed: ' + data.error);
        }
      } catch (error) {
        console.error('Error:', error);
        alert('An error occurred');
      }
    },
  },
};
</script>

<style scoped>
/* Add your styles ici */
</style>
